import React from "react";
import Cards from "./Cards";

export function Home() {
  return (
    <>
      
      <Cards />
    </>
  );
}
